import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import './PreSendingPage.css';

function PreSendingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <section className="pre-send-title1">
      {isMobile ? (
        <>
        <div className="header-section-mobile">
        <h2>Create Your UUTracking <br /> Number and Barcode <br /> in 3 Easy Steps!</h2>
        <button className="send-button" onClick={() => navigate('/send-letter')}>
            Get Started Now
          </button>
        </div>
        </>
      ) : (
        <>
        <div className="header-section-pc">
        <h2>Create Your UUTracking <br /> Number and Barcode <br /> in 3 Easy Steps!</h2>
        <button className="send-button" onClick={() => navigate('/send-letter')}>
            Get Started Now
          </button>
        </div>
        </>
      )}

      {isMobile ? (
        <>
          <div className="step-card-mobile">
            <h2>Step 1: Enter the Sender’s Address!</h2>
            <img src="/path/to/step1-image.png" alt="Step 1 Image" className="step-image-mobile" />
            <p>
              Fill in your address, as return address, in the form. This will be used in case the mail cannot be delivered and needs to be returned to sender (RTS). Don’t forget to include your Unit or Apt number if applicable.
            </p>
          </div>

          <div className="step-card-mobile">
            <h2>Step 2: Enter the Recipient’s Address!</h2>
            <img src="/path/to/step2-image.png" alt="Step 2 Image" className="step-image-mobile" />
            <p>
              Enter the recipient’s address (delivery address) in the form.
              <br />
              • If the recipient is in the United States, our system will assist you in verifying the address reliability. However, you are still responsible for reviewing the address before verification and double-checking it after verification.
              <br />
              • If the recipient is outside the United States, the system will not verify the address reliability.
            </p>
          </div>

          <div className="step-card-mobile">
            <h2>Step 3: Choose Your Mail Type and Print All!</h2>
            <img src="/path/to/step3-image.png" alt="Step 3 Image" className="step-image-mobile" />
            <p>
              A tracking number starting with 'UUTK' will be instantly generated for tracking purposes—note that it is only valid on UUTracking.com. You can download and print the PDF containing the barcode, address, and tracking number, or let us print it and send to you if you don't have a printer.
            </p>
          </div>

          <button className="send-button" onClick={() => navigate('/send-letter')}>
            Get Started Now
          </button>
        </>
      ) : (
        <>
          <div className="step-card-pc">
            <div className="step-content">
              <h2>Step 1: Enter the Sender’s Address!</h2>
              <p>
                Fill in your address, as return address, in the form. This will be used in case the mail cannot be delivered and needs to be returned to sender (RTS). Don’t forget to include your Unit or Apt number if applicable.
              </p>
            </div>
            <img src="/path/to/step1-image.png" alt="Step 1 Image" className="step-image-right" />
          </div>

          <div className="step-card-pc">
            <img src="/path/to/step2-image.png" alt="Step 2 Image" className="step-image-left" />
            <div className="step-content">
              <h2>Step 2: Enter the Recipient’s Address!</h2>
              <p>
                Enter the recipient’s address (delivery address) in the form.
                <br />
                • If the recipient is in the United States, our system will assist you in verifying the address reliability. However, you are still responsible for reviewing the address after verification.
                <br />
                • If the recipient is outside the United States, the system will not verify the address reliability.
              </p>
            </div>
          </div>

          <div className="step-card-pc">
            <div className="step-content">
              <h2>Step 3: Choose Your Mail Type and Print All!</h2>
              <p>
                A tracking number starting with 'UUTK' will be instantly generated for tracking purposes—note that it is only valid on UUTracking.com. You can download and print the PDF containing the barcode, address, and tracking number, or let us print it and send to you if you don't have a printer.
              </p>
            </div>
            <img src="/path/to/step3-image.png" alt="Step 3 Image" className="step-image-right" />
          </div>

          <button className="send-button" onClick={() => navigate('/send-letter')}>
            Get Started Now
          </button>
        </>
      )}
    </section>
  );
}

export default PreSendingPage;