// src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://uutracking.com/pdf-gen', // 后端 API 的基础路径，请根据实际情况修改
  withCredentials: true, // 如果需要发送 cookies
});

// 添加请求拦截器，在请求头中携带 Token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // 从 localStorage 获取 Token
    // alert(token)
    if (token) {
      config.headers['satoken'] = `${token}`; // 设置请求头
      // config.headers['Authorization'] = `Bearer ${token}`; // 设置请求头
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const generatePdf = (requestPayload) => {
  let response = api.post('/generate-pdf', { requestPayload })
  return response;
};

