// src/components/SendLetter/SendLetter.jsx
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './SendLetter.css';
import { send as sendApi } from '../../services/api_mail';
import { validateAddress } from '../../services/api_address';

function SendLetter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDomestic, setIsDomestic] = useState(true);
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data)
    try {
      // 根据是否国内，准备不同的数据结构
      const requestData = {
        originFullName: data.senderName,
        originAddress1: data.senderStreet,
        originAddress2: data.senderAddress2 || '',
        originCity: data.senderCity,
        originState: data.senderState,
        originZip: data.senderZip,
        type1: 1, // 假设类型1为用户自写
        type2: isDomestic ? 1 : 2, // 国内1，国际2
        type3: 2, // 假设类型3为普通信件
        type4: 0, // 预留字段

        // 收件人信息
        destinationFullName: data.recipientName,
        createTime: new Date().toISOString(), // 创建时间
      };

      if (isDomestic) {
        // 国内邮件
        requestData.destinationAddress1 = data.recipientStreet;
        requestData.destinationAddress2 = data.recipientAddress2 || '';
        requestData.destinationCity = data.recipientCity;
        requestData.destinationState = data.recipientState;
        requestData.destinationZip = data.recipientZip;

      } else {
        // 国际邮件
        requestData.destinationAddress1 = data.recipientFullAddress;
        requestData.destinationState = data.recipientCountryZip;
      }

      // 发送请求到后端
      const response = await sendApi(requestData);

      if (response.data.code === 200) {
        // 发送成功，导航到成功页面，或显示跟踪编号
        const trackingNumber = response.data.data.trackingNumber;
        const imb = response.data.data.imb;
        console.log(response)
        alert(`邮件已成功创建，跟踪编号：${trackingNumber}, imb: ${imb}`);
        // 导航到新的页面，传递 trackingNumber 和其他必要的信息
        navigate('/choose-barcode-option', { state: { trackingNumber, requestData, imb } });

      } else {
        // 处理后端返回的错误
        alert(`发送邮件失败：${response.data.msg}`);
      }
    } catch (error) {
      console.log(error);
      alert('发送邮件时发生错误，请稍后重试');
    }
  };

  const validateSenderAddress = async () => {
    try {
      // 收集寄件人地址信息
      const street1 = getValues('senderStreet');
      const street2 = getValues('senderAddress2') || '';
      const city = getValues('senderCity');
      const state = getValues('senderState');
      const zip = getValues('senderZip');

      if (!street1 || !city || !state) {
        alert('请先填写完整的地址信息');
        return;
      }

      // 合并街道地址
      const street = street1 + (street2 ? ' ' + street2 : '');

      // 准备 API 参数
      const addressParams = {
        street,
        city,
        state,
        zip,
      };

      // 调用地址验证 API
      const response = await validateAddress(addressParams);

      const data = response.data;
      console.log(data)
      if (response.data.code === 200) {
        const data = JSON.parse(response.data.data); // 解析 JSON 字符串
        console.log(data)
        if (data.length > 0) {
          // 取第一个候选地址
          const candidate = data[0];

          // 更新表单中的地址信息
          setValue('senderStreet', candidate.delivery_line_1);
          if (candidate.delivery_line_2) {
            setValue('senderAddress2', candidate.delivery_line_2);
          } else {
            setValue('senderAddress2', '');
          }
          setValue('senderCity', candidate.components.city_name);
          setValue('senderState', candidate.components.state_abbreviation);

          // 构建11位邮编：5位邮编 + 4位扩展码 + 2位投递点代码
          const zipCode = candidate.components.zipcode + candidate.components.plus4_code + candidate.components.delivery_point;
          setValue('senderZip', zipCode);

          alert('地址已标准化');
        } else {
          alert('地址无效，请检查并重试');
        }
      }
    } catch (error) {
      console.error(error);
      alert('地址验证时发生错误，请稍后重试');
    }
  };

  const validateRecipientAddress = async () => {
    if (!isDomestic) {
      // 国际地址不进行验证
      return;
    }
    try {
      // 收集收件人地址信息
      const street1 = getValues('recipientStreet');
      const street2 = getValues('recipientAddress2') || '';
      const city = getValues('recipientCity');
      const state = getValues('recipientState');
      const zip = getValues('recipientZip');

      if (!street1 || !city || !state) {
        alert('请先填写完整的地址信息');
        return;
      }

      // 合并街道地址
      const street = street1 + (street2 ? ' ' + street2 : '');

      // 准备 API 参数
      const addressParams = {
        street,
        city,
        state,
        zip,
      };

      // 调用地址验证 API
      const response = await validateAddress(addressParams);

      const data = response.data;
      if (response.data.code === 200) {
        const data = JSON.parse(response.data.data); // 解析 JSON 字符串
        console.log(data)
        if (data.length > 0) {

          // 取第一个候选地址
          const candidate = data[0];

          // 更新表单中的地址信息
          setValue('recipientStreet', candidate.delivery_line_1);
          if (candidate.delivery_line_2) {
            setValue('recipientAddress2', candidate.delivery_line_2);
          } else {
            setValue('recipientAddress2', '');
          }
          setValue('recipientCity', candidate.components.city_name);
          setValue('recipientState', candidate.components.state_abbreviation);

          // 构建11位邮编：5位邮编 + 4位扩展码 + 2位投递点代码
          const zipCode = candidate.components.zipcode + candidate.components.plus4_code + candidate.components.delivery_point;
          setValue('recipientZip', zipCode);

          alert('地址已标准化');
        } else {
          alert('地址无效，请检查并重试');
        }
      }
    } catch (error) {
      console.error(error);
      alert('地址验证时发生错误，请稍后重试');
    }
  };

  return (
    <div className="send-letter">
      {/* 顶部文字讲解区 */}
      <section className="description">
        <h1>{t('sendLetterTitle')}</h1>
        <p>{t('sendLetterDescription')}</p>
      </section>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* 寄件人信息 */}
        <h2>{t('senderInfo')}</h2>
        <div className="form-group">
          <label>{t('fullName')}</label>
          <input type="text" {...register('senderName', { required: true })} />
          {errors.senderName && <p className="error">{t('requiredField')}</p>}
        </div>
        <div className="form-group">
          <label>{t('streetAddress')}</label>
          <input type="text" {...register('senderStreet', { required: true })} />
          {errors.senderStreet && <p className="error">{t('requiredField')}</p>}
        </div>
        <div className="form-group">
          <label>{t('addressLine2')}</label>
          <input type="text" {...register('senderAddress2')} />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>{t('city')}</label>
            <input type="text" {...register('senderCity', { required: true })} />
            {errors.senderCity && <p className="error">{t('requiredField')}</p>}
          </div>
          <div className="form-group">
            <label>{t('state')}</label>
            <input type="text" {...register('senderState', { required: true })} />
            {errors.senderState && <p className="error">{t('requiredField')}</p>}
          </div>
          <div className="form-group">
            <label>{t('zipCode')}</label>
            <input type="text" {...register('senderZip', { required: true })} />
            {errors.senderZip && <p className="error">{t('requiredField')}</p>}
          </div>
        </div>
        <button type="button" onClick={validateSenderAddress}>{t('validateAddress')}</button>

        {/* 收件人信息 */}
        <h2>{t('recipientInfo')}</h2>
        <div className="form-group">
          <label>{t('recipientLocation')}</label>
          <select value={isDomestic ? 'domestic' : 'international'} onChange={(e) => setIsDomestic(e.target.value === 'domestic')}>
            <option value="domestic">{t('domestic')}</option>
            <option value="international">{t('international')}</option>
          </select>
        </div>

        {isDomestic ? (
          <>
            <div className="form-group">
              <label>{t('fullName')}</label>
              <input type="text" {...register('recipientName', { required: true })} />
              {errors.recipientName && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('streetAddress')}</label>
              <input type="text" {...register('recipientStreet', { required: true })} />
              {errors.recipientStreet && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('addressLine2')}</label>
              <input type="text" {...register('recipientAddress2')} />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('city')}</label>
                <input type="text" {...register('recipientCity', { required: true })} />
                {errors.recipientCity && <p className="error">{t('requiredField')}</p>}
              </div>
              <div className="form-group">
                <label>{t('state')}</label>
                <input type="text" {...register('recipientState', { required: true })} />
                {errors.recipientState && <p className="error">{t('requiredField')}</p>}
              </div>
              <div className="form-group">
                <label>{t('zipCode')}</label>
                <input type="text" {...register('recipientZip', { required: true })} />
                {errors.recipientZip && <p className="error">{t('requiredField')}</p>}
              </div>
            </div>
            <button type="button" onClick={validateRecipientAddress}>{t('validateAddress')}</button>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>{t('fullName')}</label>
              <input type="text" {...register('recipientName', { required: true })} />
              {errors.recipientName && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('fullAddressWithoutCountry')}</label>
              <textarea {...register('recipientFullAddress', { required: true })}></textarea>
              {errors.recipientFullAddress && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('countryAndZipCode')}</label>
              <input type="text" {...register('recipientCountryZip', { required: true })} />
              {errors.recipientCountryZip && <p className="error">{t('requiredField')}</p>}
            </div>
          </>
        )}

        <button type="submit">{t('nextStep')}</button>
      </form>
    </div>
  );
}

export default SendLetter;