// src/components/Track.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom'; // 导入 useNavigate
import './Track.css';
import { getUserInfo } from '../../services/api';
import { getMailInfoByCheck, getMailInfoByPeek } from '../../services/api_mail';
import { getTrackAll } from '../../services/api_track';

import { Link } from 'react-router-dom';

function Track() {
    const { t } = useTranslation();
    const { trackingNumber: initialTrackingNumber } = useParams();
    const [trackingNumber, setTrackingNumber] = useState(initialTrackingNumber || '');
    const [mailInfo, setMailInfo] = useState(null);
    const [trackingRecords, setTrackingRecords] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // 添加加载状态
    const navigate = useNavigate();

    // 定义日期格式选项
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    await getUserInfo().then(response => {
                        if (response.data.code === 200) {
                            setIsLoggedIn(true);
                        } else {
                            setIsLoggedIn(false);
                        }
                    }).catch(error => {
                        localStorage.removeItem('token');
                    });
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                setIsLoggedIn(false);
            } finally {
                setIsLoading(false); // 登录状态检查结束
            }
        };
        checkLoginStatus();
    }, []);

    useEffect(() => {
        if (!isLoading && trackingNumber) {
            fetchMailInfo();
            fetchTrackingRecords();
        }
    }, [trackingNumber, isLoading]);

    const fetchMailInfo = async () => {
        try {
            let response;
            // alert(isLoggedIn)
            if (isLoggedIn) {
                response = await getMailInfoByCheck(trackingNumber);
            } else {
                response = await getMailInfoByPeek(trackingNumber);
            }
            if (response.data.code === 200) {
                setMailInfo(response.data.data);
            } else {
                setMailInfo(null);
            }
        } catch (error) {
            console.log(error);
            alert(t('networkError'));
        }
    };

    const fetchTrackingRecords = async () => {
        try {
            const response = await getTrackAll(trackingNumber);
            if (response.data.code === 200) {
                setTrackingRecords(response.data.data);
            } else {
                setTrackingRecords([]);
            }
        } catch (error) {
            alert(t('networkError'));
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const inputTrackingNumber = e.target.elements.trackingNumber.value.trim();
        if (inputTrackingNumber) {
            setTrackingNumber(inputTrackingNumber);
            navigate(`/track/${inputTrackingNumber}`); // 更新 URL
        } else {
            setTrackingNumber('');
        }
    };

    function convertArrayToDate(dateArray) {
        if (Array.isArray(dateArray) && dateArray.length >= 5) {
            const [year, month, day, hour, minute] = dateArray;
            return new Date(year, month - 1, day, hour, minute);
        }
        return new Date(2024, 10, 1, 1, 1);
    }

    function mailTypeTranslator(mailType) {
        console.log(mailInfo)
        // 邮件类型，4位数。第1位：自写1/代发2；第2位：国内1/国际2；第3位：明信片1/普通letter 2；第4位：0，预留
        if (mailType === 1210) {
            return t('UUTrackingLetterPostcardInternational')
        } else if (mailType === 1220) {
            return t('UUTrackingLargeEnvolopeInternational')
        } else if (mailType === 1120) {
            return t('UUTrackingLargeEnvolopeDomestic')
        } else if (mailType === 1110) {
            return t('UUTrackingLetterPostcardDomestic')
        }
        return t('UUTrackingUnknownMailType')
    }

    function mailStatusTranslator(mailStatus) {
        console.log(mailInfo)
        // 状态码 200已经创建，250在途中，300到达（不再更新）
        if (mailStatus === 200) {
            return t('UUTrackingMailTypeStatusCreate')
        } else if (mailStatus === 250) {
            return t('UUTrackingMailTypeStatusInTransit')
        } else if (mailStatus === 300) {
            return t('UUTrackingMailTypeStatusDelivered')
        }
        return t('UUTrackingMailTypeStatusUnknown')
    }

    function mapEvent(eventCode) {
        const eventMapping = {
            1: t('userAddedDeliveryRecord'),
            2: t('postageCancellation'),
            3: t('originPrimaryProcessing'),
            4: t('destinationProcessing'),
            5: t('destinationSorting'),
            6: t('destinationMailboxDrop'),
            7: t('delivery'),
            8: t('internationalOutboundScan')
        };
        return eventMapping[eventCode] || t('unknownEvent', `未知事件（${eventCode}）`);
    }


    return (
        <div className="track-page">
            <form className="search-form" onSubmit={handleSearch}>
                <input type="text" name="trackingNumber" placeholder={t('enterTrackingNumber')} defaultValue={trackingNumber} />
                <button type="submit">{t('search')}</button>
            </form>

            {trackingNumber && (
                <div className="current-tracking-number">
                    <h2>{t('currentTrackingNumber')}: {trackingNumber}</h2>
                </div>
            )}

            {mailInfo && (
                <div className="mail-info card">
                    {isLoggedIn ? (
                        <div className="details">
                            <h3>{t('mailDetails')}</h3>
                            <p>{t('mailType')}: {mailTypeTranslator(mailInfo.type)}</p>
                            <p>{t('originAddress')}: {mailInfo.originAddress1}, {mailInfo.originCity}, {mailInfo.originState} {mailInfo.originZip}</p>
                            <p>{t('destinationAddress')}: {mailInfo.destinationAddress1}, {mailInfo.destinationCity}, {mailInfo.destinationState} {mailInfo.destinationZip}</p>
                            <p>{t('trackingNumber')}: {mailInfo.trackingNumber}</p>
                            <p>{t('status')}: {mailStatusTranslator(mailInfo.status)}</p>
                        </div>
                    ) : (
                        <div className="summary">
                            <h3>{t('mailSummarys')}</h3>
                            <p>{t('origin')}: {mailInfo.originCity}, {mailInfo.originState}</p>
                            <p>{mailInfo.destinationCity ? `${t('destination')}: ${mailInfo.destinationCity}, ${mailInfo.destinationState}` : `${t('destination')}: ${mailInfo.destinationCountry}`}</p>
                            <p>{t('eventTime')}: {convertArrayToDate(mailInfo.eventTime).toLocaleString('en-US', dateOptions)}</p>
                        </div>
                    )}
                </div>
            )}

            {trackingRecords.length > 0 ? (
                <div className="timeline">
                    <h3>{t('trackingUpdates')}</h3>
                    <ul>
                        {trackingRecords.map((record) => (
                            <li key={record.trackingId}>
                                <div className="timeline-content">
                                    <span className="timeline-date">
                                        {record.eventTime && convertArrayToDate(record.eventTime)
                                            ? convertArrayToDate(record.eventTime).toLocaleString('en-US', dateOptions)
                                            : t('invalidDate')}
                                    </span>
                                    <p>{t('event')}: {mapEvent(record.event)}</p>
                                    <p>{t('location')}: {record.currentLocation}, {record.currentCity}, {record.currentState} {record.currentZip}</p>
                                    <p>{t('status')}: {record.status === 1 ? t('normal') : t('abnormal')}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                trackingNumber && <p>{t('noTrackingRecords')}</p>
            )}
        </div>
    );
}



export default Track;
