// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

// 导入翻译资源
import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh/translation.json';

const resources = {
  en: { translation: translationEN },
  zh: { translation: translationZH },
};

i18n
  .use(LanguageDetector) // 使用语言检测插件
  .use(initReactI18next)  // 将 i18n 实例传递给 react-i18next
  .init({
    resources,
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false, // React 已经默认防止 XSS
    },
  });

export default i18n;
