// src/services/api_validate.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://uutracking.com/api/address', // 请替换为您的后端域名
  withCredentials: true,
});

// 添加请求拦截器（如果需要）

export const validateAddress = (addressData) => {
  return api.post('/validate', addressData);
};