import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next'; // 导入 I18nextProvider 以支持多语言
import i18n from './i18n'; // 导入 i18n 配置
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import TrackingSection from './components/TrackingSection/TrackingSection'; // 引入 TrackingSection 组件
import Footer from './components/Footer/Footer';
import SendLetter from './components/SendLetter/SendLetter'; // 引入 SendLetter 组件
import './styles/App.css'; // 引入全局样式
import ChooseBarcodeOption from './components/ChooseBarcodeOption/ChooseBarcodeOption';
import MailBarcodeForm from './components/MailBarcodeForm/MailBarcodeForm';
import Track from './components/Track/Track';
import MainPageContent_1 from './components/MainPageContent_1/MainPageContent_1';
import PreSendingPage from './components/PreSendingPage/PreSendingPage'
import UserDashboard from './components/UserDashboard/UserDashboard';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      {/* 将整个应用放在 Router 中，以支持路由功能 */}
      <Router>
        <div className="app">
          <Header />

          {/* 路由部分 */}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {/* 当访问根路径 "/" 时，显示 TrackingSection 和 Main 组件 */}
                  <TrackingSection />
                  <MainPageContent_1 />
                  {/* <Main /> */}
                </>
              }
            />
            <Route 
              path="/send-letter" 
              element={<SendLetter />} 
            />
            <Route 
              path="/pre-send" 
              element={<PreSendingPage />} 
            />
            <Route 
              path="/user-dashboard" 
              element={<UserDashboard />} 
            />
            {/* 可以在这里添加更多路由 */}
            <Route path="/track/:trackingNumber" element={<Track />} />
            <Route path="/track" element={<Track />} />
            <Route path="/choose-barcode-option" element={<ChooseBarcodeOption />} />
            <Route path="/mail-barcode" element={<MailBarcodeForm />} />
          </Routes>

          {/* Main 和 Footer 通常不需要重复渲染，可以直接放在路由外部 */}
          <Footer />
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
