
import { useTranslation } from 'react-i18next';
import './Footer.css';
import React, { useState } from 'react';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-links">
        <a href="#">{t('footerAboutUs')}</a>
        <a href="#">{t('footerHelp')}</a>
        <a href="#">{t('footerPrivacy')}</a>
        <a href="#">{t('footerTerms')}</a>
      </div>
      <div className="footer-contact">
        <p>{t('footerContactEmail')}</p>
        <p>{t('footerContactPhone')}</p>
      </div>
      <div className="footer-copy">
        <p>{t('footerCopy')}</p>
      </div>
    </footer>
  );
}

export default Footer;
