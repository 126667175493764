// src/components/MailBarcodeForm.jsx
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import axios from 'axios';
import './MailBarcodeForm.css';

function MailBarcodeForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { trackingNumber, requestData } = location.state || {};

  if (!trackingNumber || !requestData) {
    navigate('/send-letter');
    return null;
  }

  // 预填充数据
  const initialValues = {
    recipientName: requestData.originFullName,
    address1: requestData.originAddress1,
    address2: requestData.originAddress2,
    city: requestData.originCity,
    state: requestData.originState,
    zip: requestData.originZip,
    email: '' // 登录用户可自动填入
  };

  const onFinish = async (values) => {
    try {
      // 构建请求数据，生成新的 trackingNumber
      const response = await axios.post('/mail/send', {
        // 填入必要的字段
        originFullName: 'Our Company', // 我们的公司名称
        originAddress1: 'Our Address',
        originCity: 'Our City',
        originState: 'Our State',
        originZip: 'Our Zip',
        destinationFullName: values.recipientName,
        destinationAddress1: values.address1,
        destinationAddress2: values.address2 || '',
        destinationCity: values.city,
        destinationState: values.state,
        destinationZip: values.zip,
        type1: 2, // 代发
        type2: 1, // 国内
        type3: 2,
        type4: 0,
        createTime: new Date().toISOString(),
      });

      if (response.data.code === 200) {
        // 生成成功，提示用户
        const newTrackingNumber = response.data.data.trackingNumber;
        alert(`您的条码将通过快递寄送，跟踪编号：${newTrackingNumber}`);
        // 导航回首页或其他页面
        navigate('/');
      } else {
        alert(`提交失败：${response.data.msg}`);
      }
    } catch (error) {
      console.error(error);
      alert('网络错误，请稍后重试');
    }
  };

  return (
    <div className="mail-barcode-form">
      <h1>填写收货地址</h1>
      <Form form={form} initialValues={initialValues} onFinish={onFinish}>
        <Form.Item name="recipientName" label="收信人/公司" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address1" label="地址行1" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="address2" label="地址行2">
          <Input />
        </Form.Item>
        <Form.Item name="city" label="城市" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="state" label="州" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="zip" label="邮编" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="电子邮箱" rules={[{ required: true, type: 'email' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">寄送</Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default MailBarcodeForm;
