import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material'; // 导入响应式工具
import './MainPageContent_1.css';

function MainPageContent_1() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme(); // 如果你正在使用 Material-UI，确保导入并使用 theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 判断是否为移动端（≤768px）
  const handleButtonClick = () => {
    // alert("cli")
    navigate(`/pre-send`);
  };
  return (
    <section className="title1">
      {isMobile ? (
        <>
          <div id="title1-mobile">
            <h2>Make it possible to track <br />postcards & regular letters</h2>
            <div id="title2-mobile">
                <h2>With UUTracking, you can get a unique tracking number and barcode for each postcard, regular letter, and even large envelope you send anywhere from the United States, with the possibility of limited tracking 🎉</h2>
            </div>
          </div>
        </>
      ) : (
        <>
          <div id="title1-pc" className="centered-content">
            <h2>Make it possible to track <br />postcards & regular letters</h2>
            <div id="title2-pc">
                <h2>With UUTracking, you can get a unique tracking number and barcode for each postcard, regular letter, and even large envelope you send anywhere from the United States, with the possibility of limited tracking 🎉</h2>
            </div>
          </div>
        </>
      )}
      <button className="title3-button centered-content" onClick={handleButtonClick}>
          Send a Trackable Mail
        </button>
    </section>
  );
}

export default MainPageContent_1;