// src/components/TrackingSection.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './TrackingSection.css';

function TrackingSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = useState('');

  const handleInputChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const handleButtonClick = () => {
    if (trackingNumber.trim()) {
      navigate(`/track/${trackingNumber.trim()}`);
    } else {
      alert(t('trackingNumberRequired')); // 提示用户输入追踪号码
    }
  };

  return (
    <section className="tracking-section">
      <h2>Already have a tracking number?<br />{t('trackingSectionTitle')}</h2>
      <div className="tracking-form">
        <input
          type="text"
          placeholder={t('trackingPlaceholder')}
          className="tracking-input"
          value={trackingNumber}
          onChange={handleInputChange}
        />
        <button className="tracking-button" onClick={handleButtonClick}>
          {t('trackingButton')}
        </button>
      </div>
    </section>
  );
}

export default TrackingSection;