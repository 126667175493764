// src/components/AuthModal/AuthModal.jsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
  Alert,
  CircularProgress,
  Divider,
  FormControlLabel,
  Checkbox,
  Link as MuiLink,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function AuthModal({ isOpen, onRequestClose, onSubmit }) {
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true); // 在组件内部管理登录/注册模式

  // 定义验证模式
  const schema = yup.object().shape({
    email: yup.string().email('请输入有效的邮箱').required('邮箱不能为空'),
    password: yup.string().min(6, '密码至少为6位').max(16, '密码最多为16位').required('密码不能为空'),
    confirmPassword: isLoginMode
      ? yup.string().notRequired()
      : yup
          .string()
          .oneOf([yup.ref('password'), null], '密码必须匹配')
          .required('确认密码不能为空'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmitError('');
    try {
      await onSubmit(data, isLoginMode); // 传递 isLoginMode
    } catch (error) {
      setSubmitError('操作失败，请检查您的输入或稍后再试');
    } finally {
      setIsSubmitting(false);
    }
  };

  // 切换登录和注册模式
  const switchMode = () => {
    setIsLoginMode((prevMode) => !prevMode); // 切换登录和注册模式
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {isLoginMode ? '登录' : '注册'}
        <IconButton
          aria-label="close"
          onClick={onRequestClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 1,
          }}
        >
          <TextField
            label="邮箱"
            type="email"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            required
          />
          <TextField
            label="密码"
            type="password"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            fullWidth
            required
          />
          {!isLoginMode && (
            <TextField
              label="确认密码"
              type="password"
              {...register('confirmPassword')}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              fullWidth
              required
            />
          )}
          <FormControlLabel
            control={<Checkbox {...register('remember')} color="primary" />}
            label="记住我"
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isLoginMode && (
              <MuiLink component="button" onClick={() => {}} variant="body2">
                忘记密码？
              </MuiLink>
            )}
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{ position: 'relative' }}
          >
            {isLoginMode ? '登录' : '注册'}
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{ position: 'absolute', right: 16, top: '50%', marginTop: '-12px' }}
              />
            )}
          </Button>
        </Box>
        <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
          {isLoginMode ? '还没有账号？' : '已经有账号了？'}
          <MuiLink component="button" onClick={switchMode} sx={{ ml: 1 }}>
            {isLoginMode ? '注册' : '登录'}
          </MuiLink>
        </Typography>
      </DialogContent>
      <Divider />
      <Box sx={{ p: 2 }}>
        {/* 这里可以添加其他内容，例如社交登录按钮，目前未启用 */}
      </Box>
    </Dialog>
  );
}

export default AuthModal;