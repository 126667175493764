// src/components/Header/Header.jsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom'; // 使用别名避免与 MUI 的 Link 混淆
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  CssBaseline,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language'; // 语言图标
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useTheme, useMediaQuery } from '@mui/material'; // 导入响应式工具
import uulogo from '../../assets/images/uulogo.png';
import AuthModal from '../AuthModal/AuthModal';
import CustomButton from '../CustomButton'; // 导入自定义按钮组件
import {
  register as registerApi,
  login as loginApi,
  getUserInfo,
  logout as logoutApi,
} from '../../services/api';

function Header() {
  const { t, i18n } = useTranslation();
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  // 独立的 anchorEl 状态
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 判断是否为移动端（≤768px）

  useEffect(() => {
    if (user) {
      console.log("User updated:", user);
    }
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUserInfo()
        .then((response) => {
          console.log(response);
          if (response.data.code === 200) {
            setUser(response.data);
          } else {
            localStorage.removeItem('token');
          }
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem('token');
        });
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
  };

  const openAuthModal = () => {
    setAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    setAuthModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      const response = await logoutApi();
      console.log(response);
      if (response.data.code === 200) {
        localStorage.removeItem('token');
        alert("已退出登录: " + response.data.message);
        setUser(null);
      } else {
        alert("退出登录失败: " + response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert('退出登录失败，请稍后再试');
    }
    window.location.reload();
  };

  const handleAuthSubmit = async (data, isLoginMode) => {
    try {
      let response;
      if (isLoginMode) {
        // 登录逻辑
        response = await loginApi(data.email, data.password);
        console.log(response);
        if (response.data.code === 200) {
          const token = response.data.data.satoken;
          localStorage.setItem('token', token);
        } else {
          alert(response.data.message);
          return;
        }
      } else {
        // 注册逻辑
        response = await registerApi(data.email, data.password);
        if (response.data.code === 200) {
          alert("注册成功！");
          const token = response.data.data.satoken;
          localStorage.setItem('token', token);
        } else {
          alert("注册失败！");
          return;
        }
      }
      response = await getUserInfo();
      setUser(response.data);
      closeAuthModal();
    } catch (error) {
      alert('操作失败，请检查您的输入或稍后再试');
    }
  };

  // 语言菜单的事件处理
  const handleLanguageMenuOpenHandler = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  // 用户菜单的事件处理
  const handleUserMenuOpenHandler = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  return (
    <>
      <CssBaseline /> {/* 确保基础样式一致 */}
      <AppBar position="static" sx={{ backgroundColor: '#ffc2e9', fontFamily: 'Circular Std, sans-serif' }}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          {/* 左侧 Logo 和 UUTracking */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <RouterLink to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <img src={uulogo} alt="UUTracking Logo" style={{ width: '50px', height: '50px' }} />
            </RouterLink>
            <Typography variant="h6" component="div" color="secondary" sx={{ marginLeft: 2, fontFamily: 'Circular Std, sans-serif' }}>
              UUTracking
            </Typography>
          </Box>

          {/* 右侧内容 */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* 语言下拉菜单 */}
            <Box>
              <CustomButton onClick={handleLanguageMenuOpenHandler} variant="language">
                {isMobile ? <LanguageIcon /> : t('language')}
              </CustomButton>
              <Menu
                anchorEl={languageAnchorEl}
                open={Boolean(languageAnchorEl)}
                onClose={handleLanguageMenuClose}
              >
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
              </Menu>
            </Box>

            {/* 用户下拉菜单 */}
            {user ? (
              <Box sx={{ ml: 2 }}>
                <CustomButton onClick={handleUserMenuOpenHandler}>
                  {isMobile ? <AccountCircleIcon /> : t('language')}
                </CustomButton>
                <Menu
                  anchorEl={userAnchorEl}
                  open={Boolean(userAnchorEl)}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem component={RouterLink} to="/user-dashboard" onClick={handleUserMenuClose}>用户主页</MenuItem>
                  <MenuItem component={RouterLink} to="/uu-posting-orders" onClick={handleUserMenuClose}>我的 UUPosting 订单</MenuItem>
                  <MenuItem component={RouterLink} to="/uu-tracking-orders" onClick={handleUserMenuClose}>我的 UUTracking 订单</MenuItem>
                  <MenuItem onClick={() => { handleUserMenuClose(); handleLogout(); }}>退出登录</MenuItem>
                </Menu>
              </Box>
            ) : (
              <>
                <CustomButton onClick={openAuthModal}>
                  {t('login')}
                </CustomButton>
                {/* <CustomButton onClick={openAuthModal}>
                  {t('register')}
                </CustomButton> */}
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* AuthModal 组件 */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={closeAuthModal}
        onSubmit={handleAuthSubmit}
      />
    </>
  );
}

export default Header;