import React, { useState } from 'react';
import MailsPage from '../MailsPage/MailsPage'; // 引入你刚刚做的Mails页面
// import MyAccountPage from './MyAccountPage'; // 假设有MyAccountPage组件
// import ContactSupportPage from './ContactSupportPage'; // 假设有ContactSupportPage组件
import './UserDashboard.css';

const UserDashboard = () => {
  const [activePage, setActivePage] = useState('mails'); // 设置默认页面为 Mails

  // 根据当前选中的页面，渲染不同的组件
  const renderPageContent = () => {
    switch (activePage) {
      case 'mails':
        return <MailsPage />;
    //   case 'account':
    //     return <MyAccountPage />;
    //   case 'support':
    //     return <ContactSupportPage />;
      default:
        return <MailsPage />;
    }
  };

  return (
    <div className="dashboard-container">
      {/* 左侧功能栏 */}
      <aside className="sidebar">
        <div
          className={`sidebar-item ${activePage === 'mails' ? 'active' : ''}`}
          onClick={() => setActivePage('mails')}
        >
          <h3>Mails</h3>
        </div>
        <div
          className={`sidebar-item ${activePage === 'account' ? 'active' : ''}`}
          onClick={() => setActivePage('account')}
        >
          <h3>My Account</h3>
        </div>
        <div
          className={`sidebar-item ${activePage === 'support' ? 'active' : ''}`}
          onClick={() => setActivePage('support')}
        >
          <h3>Contact Support <span className="external-link-arrow">↗</span></h3>
        </div>
      </aside>

      {/* 右侧内容区域 */}
      <main className="main-content">
        {renderPageContent()} {/* 渲染具体页面内容 */}
      </main>
    </div>
  );
};

export default UserDashboard;