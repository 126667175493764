import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Button, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './MailsPage.css';

const mailsData = [
  {
    type: 'domestic',
    sender: 'Yifan Lu',
    senderCity: 'San Gabriel',
    recipient: 'Casey Zalid',
    recipientCity: 'Pasadena',
    trackingNumber: 'UUTK123456789',
    estimatedDelivery: '2024-11-01',
    latestUpdate: { status: 'In Transit', timestamp: '2024-10-20 14:35' },
    mailStatus: 'in-transit'
  },
  {
    type: 'international',
    sender: 'John Doe',
    senderCity: 'Los Angeles',
    recipient: 'Jane Smith',
    recipientCity: 'Tokyo',
    trackingNumber: 'UUTK987654321',
    estimatedDelivery: '2024-11-03',
    latestUpdate: { status: 'Delivered', timestamp: '2024-10-19 10:00' },
    mailStatus: 'delivered'
  }
  // 你可以继续添加更多邮件数据
];

const MailsPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMail, setSelectedMail] = useState(null);

  const handleMenuClick = (event, mail) => {
    setAnchorEl(event.currentTarget);
    setSelectedMail(mail);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMail(null);
  };

  const handleCopy = (trackingNumber) => {
    navigator.clipboard.writeText(trackingNumber);
    alert('Tracking number copied: ' + trackingNumber);
  };

  const handleDetails = (mail) => {
    alert('Mail Details:\nSender: ' + mail.sender + '\nRecipient: ' + mail.recipient);
  };

  const handleDelete = (mail) => {
    alert('Are you sure you want to delete this mail? Tracking: ' + mail.trackingNumber);
  };

  return (
    <div className="mails-page-container">
      {mailsData.map((mail, index) => (
        <div className="mail-card" key={index}>
          {/* 左上角的类型图标 */}
          <div className={`mail-icon ${mail.type}`}></div>

          {/* 邮件信息部分 */}
          <div className="mail-details">
            <h3>{mail.sender}, {mail.senderCity} to {mail.recipient}, {mail.recipientCity}</h3>
            <div className="tracking-info">
              <p>Tracking #: {mail.trackingNumber}</p>
              <Tooltip title="Copy">
                <IconButton onClick={() => handleCopy(mail.trackingNumber)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
            <p>Estimated Delivery: {mail.estimatedDelivery}</p>
            <p>Latest Update: {mail.latestUpdate.status} ({mail.latestUpdate.timestamp})</p>
          </div>

          {/* 右侧的状态图标 */}
          <div className={`mail-status ${mail.mailStatus}`}></div>

          {/* 控制菜单 */}
          <IconButton className="menu-button" onClick={(e) => handleMenuClick(e, mail)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Print Barcode</MenuItem>
            <MenuItem onClick={handleMenuClose}>Request Mailing Barcode</MenuItem>
            <MenuItem onClick={() => { handleDetails(selectedMail); handleMenuClose(); }}>Mail Details</MenuItem>
            <MenuItem onClick={() => { handleDelete(selectedMail); handleMenuClose(); }}>Delete</MenuItem>
          </Menu>
        </div>
      ))}
    </div>
  );
};

export default MailsPage;