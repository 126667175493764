// index.js
import './i18n'; // 引入i18n配置
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme.js';
import './index.css'; // 导入全局 CSS

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* 重置 CSS */}
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);