// src/components/ChooseBarcodeOption.jsx
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Form, Input } from 'antd';
import axios from 'axios';
import './ChooseBarcodeOption.css';
import { generatePdf } from '../../services/api_pdf.js';

function ChooseBarcodeOption() {
  const location = useLocation();
  const navigate = useNavigate();
  const { trackingNumber, requestData, imb } = location.state || {};
  console.log(requestData)
  alert(imb)
  if (!trackingNumber || !requestData) {
    // 如果没有传递必要的数据，重定向回寄信页面
    navigate('/send-letter');
    return null;
  }
  const handlePrintNow = async () => {
    alert(imb)

    try {
      // 构建请求数据
      const requestPayload = {
        type: 2,
        imb_data: {
          barcode_id: imb.slice(0, 2),         // '00'
          service_type: imb.slice(2, 5),       // '040'
          mail_class: imb.slice(5, 14),        // '903666417'
          serial_number: imb.slice(14, 20),    // '000036'
          zip_code: imb.slice(20),             // '91775122923'
        },
        tracking_no: trackingNumber,
        sender: `${requestData.originFullName}\n${requestData.originAddress1}\n${requestData.originCity}, ${requestData.originState} ${requestData.originZip}`,
        recipient: `${requestData.destinationFullName}\n${requestData.destinationAddress1}\n${requestData.destinationCity || ''}, ${requestData.destinationState} ${requestData.destinationZip || ''}`
      };
      // 发送 POST 请求获取下载链接
      const response = await fetch('https://uutracking.com/pdf-gen/generate-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // 如果需要发送凭证（如 Cookies）
        body: JSON.stringify(requestPayload)
      });
      console.log(response)

      if (response.ok) {
        const data = await response.json();
        const downloadUrl = data.downloadUrl;
        if (downloadUrl) {
          // 触发下载
          window.location.href = downloadUrl;
          // 显示打印指南
          alert('打印指南：\n1. 使用Letter纸张打印，打印后剪下条码并粘贴到信封上。\n2. 单号生成后，目的地地址无法修改，如需修改地址请务必作废当前单号和条码，并使用正确的地址重新生成新单号、新条码。\n3. 务必在下单后15天内寄出，超时将回收。');
        } else {
          alert('获取下载链接失败');
        }
      } else {
        const errorData = await response.json();
        alert(`错误: ${errorData.error || '获取下载链接失败'}`);
      }
    } catch (error) {
      console.error(error);
      alert('网络错误，请稍后重试');
    }
  };
  
//   const handlePrintNow = async () => {
//     try {
//       // 构建请求数据
//       const requestPayload = {
//         type: 2,
//         imb_data: {
//           service_type: '040',
//           mail_class: '903666417',
//           barcode_id: '00',
//           serial_number: requestData.imbNumber || '000036', // 您需要确保获取到 imbNumber
//           zip_code: requestData.destinationZip || '00418000100',
//           delivery_point: ''
//         },
//         tracking_no: trackingNumber,
//         sender: `${requestData.originFullName}\n${requestData.originAddress1}\n${requestData.originCity}, ${requestData.originState} ${requestData.originZip}`,
//         recipient: `${requestData.destinationFullName}\n${requestData.destinationAddress1}\n${requestData.destinationCity || ''}, ${requestData.destinationState} ${requestData.destinationZip || ''}`
//       };

//       // 发送 Post 请求获取下载链接
//     //   const response = await axios.post('http://127.0.0.1:5000/generate-pdf', {requestPayload });
//       const response = await generatePdf(requestPayload);
//       if (response.status === 200) {
//         const downloadUrl = response.data.url;
//         // 触发下载
//         window.location.href = downloadUrl;
//         // 显示打印指南
//         alert('打印指南：\n1. 使用Letter纸张打印，打印后剪下条码并粘贴到信封上。\n2. 单号生成后，目的地地址无法修改，如需修改地址请务必作废当前单号和条码，并使用正确的地址重新生成新单号、新条码。\n3. 务必在下单后15天内寄出，超时将回收。');
//       } else {
//         alert('获取下载链接失败');
//       }
//     } catch (error) {
//       console.error(error);
//       alert('网络错误，请稍后重试');
//     }
//   };

  const handleMailBarcode = () => {
    // 导航到邮寄条码表单页面
    navigate('/mail-barcode', { state: { trackingNumber, requestData } });
  };

  return (
    <div className="choose-barcode-option">
      <h1>成功生成单号：{trackingNumber}</h1>
      <div className="options">
        <Card className="option-card" style={{ backgroundColor: '#ffe6f2' }}>
          <h2>立即打印</h2>
          <p>使用打印机打印条码，并贴在信封/明信片上。</p>
          <Button type="primary" onClick={handlePrintNow}>立即打印</Button>
        </Card>
        <Card className="option-card" style={{ backgroundColor: '#ffe6f2' }}>
          <h2>邮寄条码</h2>
          <p>由我们为您打印条码，1-3天邮寄到您的手中！</p>
          <Button type="primary" onClick={handleMailBarcode}>邮寄条码</Button>
        </Card>
      </div>
    </div>
  );
}

export default ChooseBarcodeOption;
