// src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://uutracking.com/api/user', // 后端 API 的基础路径，请根据实际情况修改
  withCredentials: true, // 如果需要发送 cookies
});

// 添加请求拦截器，在请求头中携带 Token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // 从 localStorage 获取 Token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // 设置请求头
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const register = (email, password) => {
  return api.post('/register', { email, password });
};

export const login = (email, password) => {
  var response = api.post('/login', { email, password });
  return response;
};

export const logout = () => {
  return api.get('/logout');
};

export const getUserInfo = () => {
  return api.get('/username');
};

// export const getMailInfoByCheck = () => {
//   return api.get('/username');
// };

// export const getMailInfoByPeek = () => {
//   return api.get('/username');
// };
