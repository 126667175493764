// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // 将 'sm' 断点设为 768px
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#B227BB', // 主要颜色
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#440022',
      contrastText: '#951f68',
    },
    background: {
      default: '#ffe8f9',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif, Circular Std',
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none', // 保持按钮文本大小写
    },
  },
  spacing: 8, // MUI 默认间距单位
  shape: {
    borderRadius: 4, // 全局圆角
  },
});

export default theme;
